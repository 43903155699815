import mapLogo from 'images/communities/Demo/mapLogo.svg'
import homeLogo from 'images/communities/Demo/homeLogo.svg'
// import secondaryLogo from 'images/communities/Demo/secondaryLogo.svg'
// import thirdLogo from 'images/communities/Demo/thirdLogo.svg'
import logo from 'images/communities/Demo/logo.svg'
import dashboardHeaderImage from 'images/communities/Demo/dashboardHeaderImage.svg'
import loginBackgroundImage from 'images/communities/Demo/loginBackgroundImage.svg'
import mapHeroImage from 'images/communities/Demo/mapHeroImage.svg'
// import footerLogoOne from 'images/communities/Demo/footerLogoOne.svg'
// import footerLogoTwo from 'images/communities/Demo/footerLogoTwo.svg'
// import footerLogoThree from 'images/communities/Demo/footerLogoThree.svg'
// import footerLogoFour from 'images/communities/Demo/footerLogoFour.svg'
// import educatorLogo from 'images/communities/Demo/educatorLogo.svg'
// import educatorSponsorLogo from 'images/communities/Demo/educatorSponsorLogo.svg'
import favicon from 'images/communities/Chicago/favicon.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'demo',
  name: 'Demo',
  // position: 'NA', Used to order cards on Homepage
  partnerSupportEmail: 'test@example.com',
  schoolSupportEmail: 'test@example.com',
  stylesheet: 'demo.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://example.com',
  // secondaryOrganizationUrl: '',
  // thirdOrganizationUrl:
  faqUrl: 'https://example.com/',
  // additionalUrls: [
  //   {
  //     src: '',
  //     title: ''
  //   }
  // ],
  // faqUrlEducator: '', // Only needed if educatorEnabled
  // howToVideos: '', // Only used in Chicago Educator Portal
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  // id: nil, Matches DB ID for the community in the Rails app
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'createdSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 41.878292, lng: -87.622578 }, // using Chicago map values
    radius: 40, //in miles
  },
  // Only relevant if Educator in enabled
  // directoryFilter: {
  //   blockList: [],
  // },
  // forumFilters: {
  //   blockList: [],
  // }
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'demo-community',
  favicon,
  mapLogo: {
    src: mapLogo,
    alt: 'Demo Hompage',
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Demo',
  },
  communityLogo: {
    src: logo,
    alt: 'Demo Logo',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImage,
    alt: '',
  },
  mapHero: {
    src: mapHeroImage,
    alt: '',
  },
  // secondaryFooterLogo: {
  //   src: secondaryLogo,
  //   alt: '',
  //   link: '',
  // },
  // secondarySideBySideLogo: {
  //   src: secondaryLogo,
  //   alt: '',
  //   link: ''
  // },
  // thirdSideBySideLogo: {
  //   src: thirdLogo,
  //   alt: '',
  //   link: '',
  // },
  // firstSubLogo: {
  //   src: secondaryLogo,
  //   alt: '',
  //   link: '',
  // },
  // secondSubLogo: {
  //   src: thirdLogo,
  //   alt: '',
  //   link: '',
  // },
  // footerLogos: [
  //   {
  //     src: footerLogoOne,
  //     alt: '',
  //     link: '',
  //   },
  //   {
  //     src: footerLogoTwo,
  //     alt: '',
  //     link: '',
  //   },
  //   {
  //     src: footerLogoThree,
  //     alt: '',
  //     link: '',
  //   },
  //   {
  //     src: footerLogoFour,
  //     alt: '',
  //     link: '',
  //   },
  // ],
  // educatorLogo: {
  //   src: educatorLogo,
  //   alt: ''
  // },
  // educatorSponsorLogo: {
  //   src: educatorSponsorLogo,
  //   alt: ''
  // },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'mapTitle',
    tagline: 'map Tagline',
    searchHeader: 'mapSearchHeader',
    cscLinkText: 'cscLinkText',
    welcomeSearchHeader: 'mapWelcomeSearchHeader',
    welcomeHeader: 'mapWelcomeHeader',
    welcomeMessage: `
      <p>mapWelcomeMessage</p>
      <p>
        This supports some <em>formatting</em>
      </p>
    `,
  },
  tagline: 'tagline',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3>partnerPortal Welcome message</h3>
        <p>
          <em>This supports some formatting</em>
          <p> Can handle <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">email links</a></p>
        </p>
      `,
    },
    profile: {
      contacts: {
        executiveDirector: 'custom contacts/ExecutiveDirector title',
      },
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3>schoolPortal Welcome message</h3>
        <p>
          <em>This supports some formatting</em>
          <p> Can handle <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">email links</a></p>
        </p>      `,
    },
    summary: {
      artsLiaison: {
        header: 'Custom artsLiaison header',
        tooltip: 'Custom artsLiaison tooltip',
      },
      partnerProgramTypes: {
        header: 'Custom partnerProgramTypes header',
      },
    },
  },
  educatorPortal: {
    welcomdMessage: `
      <h3>educatorPortal welcomeMessage</h3>
      <p>Only relevant if educatorEnabled</p>
    `,
  },
  survey: {
    getStarted: `
      <h2>survey getStarted text</h2>
      <p>Can support variables and styles</p>
    `,
    staffingQuestions: {
      modalMessage: `
        <p>Staffing Question modal message.</p>
        <p><strong>Supports formatting</strong></p>
      `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    districtSpending: {
      question: 'custom districtSpending question text',
      description: 'custom districtSpending description text',
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
    scholarshipQuestion: {
      displayOptions: {
        showExample: true,
      },
    },
    partnershipQuestion: {
      addtionalTipsModal: `
        <p>Custom partnershiopQuestion additional tips modal</p>
      `,
    },
    communityEngagement: {
      question: 'Custom communityEngagement question text',
    },
    staffingModal: ``,
    courses: {
      classMoniker: 'classMoniker',
      coursesNote: `<p>courses courseNote</p>`,
      minutesNotes: `<p>courses minutesNote</p>`,
      instructionalDepthNote: `<p>courses instructionnalDepthNote</p>`,
    },
    percentAccess: {
      question: 'Custom precentAccess question text',
      description: 'Custom percentAccess description text',
      courseEnrollmentLabel: 'Custom percentAccess courseEnrollmentLabel',
      error: 'Custom percentAccess error texr',
    },
    obstaclesSelect: {
      question: 'Custom obstaclesSelect question',
    },
    terms: `Custom Terms text`,
  },
}

// ----- SURVEY QUESTIONS CONFIG -----
// Confirm with the Community that these are the correctly enabled Survey Questions

const {
  APPROACHES,
  INTEGRATIONS,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.GENERAL,
  integrations: INTEGRATIONS.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

// ----- EDUCATOR CONFIG -----

// config.educator = {
//   communityOfPracticeEnabled: true,
// }

export default config
