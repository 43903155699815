import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import OutcomeTypesCardIcon from './outcome-types-card-icon'
import whiteCheckmarkImage from 'images/white-check.svg'
import classnames from 'classnames'
import { find } from 'lodash'
import { yankBy, isOtherType } from 'utils'

const propTypes = {
  requestedOutcomeTypes: PropTypes.arrayOf(Types.requestedInterest).isRequired,
  outcomeTypes: PropTypes.arrayOf(Types.outcomeType).isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function outcomeTypeRequested(requestedOutcomeTypes, outcomeTypeId) {
  return find(requestedOutcomeTypes, { outcomeTypeId })
}

function OutcomeTypesTabContent({
  requestedOutcomeTypes,
  outcomeTypes,
  className,
}) {
  const [otherOutcomeType, definedOutcomeTypes] = useMemo(
    () => yankBy(outcomeTypes, isOtherType),
    [outcomeTypes]
  )

  const [otherDetailsActive, setOtherDetailsActive] = useState(false)
  const controlledId = `outcomes-stat-${otherOutcomeType.id}`
  const otherOutcomeTypeRequested = outcomeTypeRequested(
    requestedOutcomeTypes,
    otherOutcomeType.id
  )

  return (
    <div className={classnames('colored-block', className)}>
      {definedOutcomeTypes.map(({ id, displayName, name }) => {
        return (
          <div
            key={id}
            className={classnames('stat-card', 'card', {
              'none-selected': !outcomeTypeRequested(requestedOutcomeTypes, id),
            })}
          >
            <div className="stat-inner-with-image">
              <div className="image-block">
                <OutcomeTypesCardIcon outcomeTypeName={name} />
                <span>
                  <img src={whiteCheckmarkImage} alt="Checkmark" />
                </span>
              </div>
              <p>{displayName}</p>
            </div>
          </div>
        )
      })}
      {otherOutcomeType && (
        <div
          key={otherOutcomeType.id}
          className={classnames('stat-card', 'card', {
            'none-selected': !otherOutcomeTypeRequested,
            'active-trigger': otherDetailsActive,
          })}
        >
          <div className="stat-inner-with-image">
            <div className="image-block">
              <OutcomeTypesCardIcon outcomeTypeName={otherOutcomeType.name} />
              <span>
                <img src={whiteCheckmarkImage} alt="Checkmark" />
              </span>
            </div>
            <p>Other</p>
            {otherOutcomeTypeRequested && (
              <button
                onClick={() => setOtherDetailsActive(!otherDetailsActive)}
                className="stat-trigger"
                aria-label="Toggle Other Details"
                aria-expanded={otherDetailsActive}
                aria-controls={controlledId}
              />
            )}
          </div>
          <div id={controlledId}>
            {otherDetailsActive && (
              <div className="stat-details">
                <div className="stat-content">
                  <ul>
                    <li>{otherOutcomeTypeRequested.otherText}</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

OutcomeTypesTabContent.propTypes = propTypes
OutcomeTypesTabContent.defaultProps = defaultProps

export default OutcomeTypesTabContent
