import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import {
  AcademicGoalsIcon,
  AntiRacistBiasIcon,
  CriticalThinkingProblemSolvingIcon,
  CulturallyResponsiveIcon,
  SchoolCultureAndClimateIcon,
  SocialEmotionalIcon,
  SocialJusticeIcon,
  TechnicalSkillsIcon,
  TraumaInformedIcon,
  OtherOutcomesIcon,
} from 'components/icons/basic'

const propTypes = {
  outcomeTypeName: PropTypes.string.isRequired,
}

const defaultProps = {}

const resourceIcons = {
  [Types.OUTCOME_TYPES.ACADEMIC_GOALS]: <AcademicGoalsIcon />,
  [Types.OUTCOME_TYPES.SOCIAL_EMOTIONAL]: <AntiRacistBiasIcon />,
  [Types.OUTCOME_TYPES.TECHNICAL_SKILLS]: <CriticalThinkingProblemSolvingIcon />,
  [Types.OUTCOME_TYPES.ANTI_RACIST_BIAS]: <CulturallyResponsiveIcon />,
  [Types.OUTCOME_TYPES.CRITICAL_THINKING_PROBLEM_SOLVING]: <SchoolCultureAndClimateIcon />,
  [Types.OUTCOME_TYPES.CULTURALLY_RESPONSIVE]: <SocialEmotionalIcon />,
  [Types.OUTCOME_TYPES.SOCIAL_JUSTICE]: <SocialJusticeIcon />,
  [Types.OUTCOME_TYPES.SCHOOL_CULTURE_AND_CLIMATE]: <TechnicalSkillsIcon />,
  [Types.OUTCOME_TYPES.TRAUMA_INFROMED]: <TraumaInformedIcon />,
  [Types.OUTCOME_TYPES.OTHER]: <OtherOutcomesIcon />,
}

function OutcomeTypesCardIcon({ outcomeTypeName }) {
  return resourceIcons[outcomeTypeName] || null
}

OutcomeTypesCardIcon.propTypes = exact(propTypes)
OutcomeTypesCardIcon.defaultProps = defaultProps

export default pure(OutcomeTypesCardIcon)
