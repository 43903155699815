import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { set } from 'utils'
import * as apiActions from 'api-actions'
import * as actions from './actions'

const reducerKey = 'profile'
const slice = 'root.partnerPortal.profile'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchFinancialAssistanceTypes]: setOnSuccess(
      'financialAssistanceTypes'
    ),
    [apiActions.fetchLeadershipCharacteristics]: setOnSuccess(
      'leadershipCharacteristics'
    ),
    [apiActions.fetchIdentityFrequencies]: setOnSuccess('identityFrequencies'),
    [apiActions.fetchApproaches]: setOnSuccess('approaches'),
    [apiActions.fetchPartnershipQuestion]: setOnSuccess('partnershipQuestion'),
    [actions.clearPartnershipQuestion]: unsetState('partnershipQuestion'),
    [actions.setAdditionalContact]: (state, { payload: contact }) =>
      set('additionalContact', contact, state),
    [actions.setCurrentProgramYearTab]: (state, { payload: tab }) =>
      set('currentProgramYearTab', tab, state),
    [actions.setCurrentPartnershipYearTab]: (state, { payload: tab }) =>
      set('currentPartnershipYearTab', tab, state),
    [actions.setPartnerships]: (state, { payload: partnerships }) =>
      set('partnerships', partnerships, state),
    // [actions.setPrograms]: (state, { payload: programs }) => set('programs', programs, state),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  financialAssistanceTypes: select('financialAssistanceTypes'),
  leadershipCharacteristics: select('leadershipCharacteristics'),
  identityFrequencies: select('identityFrequencies'),
  approaches: select('approaches'),
  additionalContact: select('additionalContact.data.attributes'),
  partnerships: select('partnerships.data.attributes'),
  partnershipQuestion: select('partnershipQuestion'),
  currentProgramYearTab: select('currentProgramYearTab'),
  currentPartnershipYearTab: select('currentPartnershipYearTab'),
}

export { reducer, selectors, reducerKey }
