import * as React from "react";
const TechnicalSkillsIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <path
      d="M38.9926 19.0004H38.7272C38.4088 19.0004 38.1434 19.1641 37.9323 19.3825C36.7658 20.9645 35.2809 22.4917 33.3715 24.0737C33.1592 24.292 33 24.565 33 24.8913V38.9639C33 39.5099 33.4246 40 34.0073 40H38.9916C39.5223 40 39.9988 39.5633 39.9988 38.9639L40 19.9827C40 19.4367 39.5766 19 38.9927 19L38.9926 19.0004Z"
      fill="white"
    />
    <path
      d="M29.4694 26.113C29.151 25.9545 28.7277 25.9545 28.4093 26.1659C26.8716 27.2216 25.2278 28.1728 23.5307 29.0701C23.2123 29.2286 23 29.5986 23 29.9673V38.9971C23 39.5255 23.4245 40 24.0071 40H28.9918C29.5224 40 29.9988 39.5772 29.9988 38.9971L30 27.0102C30 26.6402 29.7878 26.2716 29.4694 26.113Z"
      fill="white"
    />
    <path
      d="M19.5907 30.149C19.3633 29.99 19.0449 29.937 18.773 30.096C17.364 30.8369 15.9549 31.473 14.5913 31.9501C14.2274 32.0561 14 32.4802 14 32.9031V38.994C14 39.5241 14.3639 40 14.8632 40H19.1348C19.5896 40 19.998 39.576 19.998 38.994L20 30.9959C20 30.6791 19.8182 30.3611 19.5907 30.149Z"
      fill="white"
    />
    <path
      d="M37.332 18.0157L37.9937 12.9819C38.0601 12.3443 37.5964 11.7078 36.936 11.6438L31.7116 11.0061C31.0499 10.9421 30.3895 11.389 30.3231 12.0254C30.2567 12.6631 30.7204 13.2996 31.3808 13.3636L33.5626 13.6184C30.5211 16.3588 23.7766 21.8395 14.8504 24.6425C14.1887 24.8333 13.8591 25.535 14.0571 26.1715C14.2551 26.6811 14.7188 27 15.2476 27C15.3804 27 15.512 27 15.6448 26.936C24.9682 24.0048 31.9109 18.3974 35.1504 15.4021L34.8196 17.6327C34.7532 18.2704 35.2168 18.9069 35.8773 18.9709H36.0101C36.6705 19.0349 37.2656 18.5893 37.332 18.0157Z"
      fill="white"
    />
  </svg>
);
export default TechnicalSkillsIcon;
