import * as React from "react";
const SocialJusticeIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <line x1={15.4472} y1={19.2236} x2={10.4472} y2={29.2236} stroke="white" />
    <line
      y1={-0.5}
      x2={11.1803}
      y2={-0.5}
      transform="matrix(0.447214 0.894427 0.894427 -0.447214 16 19)"
      stroke="white"
    />
    <line x1={39.4472} y1={19.2236} x2={34.4472} y2={29.2236} stroke="white" />
    <line
      y1={-0.5}
      x2={11.1803}
      y2={-0.5}
      transform="matrix(0.447214 0.894427 0.894427 -0.447214 40 19)"
      stroke="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39 39C39 39.5523 38.5523 40 38 40H17C16.4477 40 16 39.5523 16 39V38C16 37.4477 16.4477 37 17 37H38C38.5523 37 39 37.4477 39 38V39Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 30C22 30.5523 21.5523 31 21 31H10C9.44772 31 9 30.5523 9 30V29C9 28.4477 9.44772 28 10 28H21C21.5523 28 22 28.4477 22 29V30Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 30C46 30.5523 45.5523 31 45 31H34C33.4477 31 33 30.5523 33 30V29C33 28.4477 33.4477 28 34 28H45C45.5523 28 46 28.4477 46 29V30Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0006 17.009C28.0843 17.5549 27.7097 18.0653 27.1638 18.149L14.9824 20.0169C14.4365 20.1006 13.9261 19.7259 13.8424 19.18L13.6908 18.1916C13.6071 17.6457 13.9818 17.1353 14.5277 17.0516L26.7091 15.1837C27.255 15.1 27.7654 15.4747 27.8491 16.0206L28.0006 17.009Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6908 17.009C26.6071 17.5549 26.9818 18.0653 27.5277 18.149L39.7091 20.0169C40.255 20.1006 40.7654 19.7259 40.8491 19.18L41.0007 18.1916C41.0844 17.6457 40.7097 17.1353 40.1638 17.0516L27.9824 15.1837C27.4365 15.1 26.9261 15.4747 26.8424 16.0206L26.6908 17.009Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 13C28.5523 13 29 13.4477 29 14L29 37C29 37.5523 28.5523 38 28 38L27 38C26.4477 38 26 37.5523 26 37L26 14C26 13.4477 26.4477 13 27 13L28 13Z"
      fill="white"
    />
  </svg>
);
export default SocialJusticeIcon;
