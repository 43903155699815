import * as React from "react";
const OtherOutcomesIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55Z"
      fill="#204A82"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4993 22C18.5355 22 21 24.465 21 27.5C21 30.5367 18.5354 33 15.4993 33C12.463 33 10 30.535 10 27.5C10 24.4633 12.4646 22 15.4993 22ZM15.4993 24.3337C13.7521 24.3337 12.3332 25.7528 12.3332 27.5002C12.3332 29.2475 13.7521 30.6667 15.4993 30.6667C17.2464 30.6667 18.6668 29.2475 18.6668 27.5002C18.6668 25.7528 17.2464 24.3337 15.4993 24.3337Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5007 22C42.537 22 45 24.465 45 27.5C45 30.5367 42.5354 33 39.5007 33C36.4645 33 34 30.535 34 27.5C34 24.4633 36.4646 22 39.5007 22ZM39.5007 24.3337C37.7536 24.3337 36.3332 25.7528 36.3332 27.5002C36.3332 29.2475 37.7536 30.6667 39.5007 30.6667C41.2478 30.6667 42.6668 29.2475 42.6668 27.5002C42.6668 25.7528 41.2478 24.3337 39.5007 24.3337Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 22C30.5367 22 33 24.465 33 27.5C33 30.5367 30.535 33 27.5 33C24.4633 33 22 30.535 22 27.5C22 24.4633 24.465 22 27.5 22ZM27.5 24.3337C25.7527 24.3337 24.3335 25.7528 24.3335 27.5002C24.3335 29.2475 25.7527 30.6667 27.5 30.6667C29.2473 30.6667 30.6665 29.2475 30.6665 27.5002C30.6665 25.7528 29.2473 24.3337 27.5 24.3337Z"
      fill="white"
    />
  </svg>
);
export default OtherOutcomesIcon;
