import * as React from "react";
const AntiRacistBiasIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43 23C43 23.5523 42.5523 24 42 24H14C13.4477 24 13 23.5523 13 23V22C13 21.4477 13.4477 21 14 21H42C42.5523 21 43 21.4477 43 22V23Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43 34C43 34.5523 42.5523 35 42 35H14C13.4477 35 13 34.5523 13 34V33C13 32.4477 13.4477 32 14 32H42C42.5523 32 43 32.4477 43 33V34Z"
      fill="white"
    />
  </svg>
);
export default AntiRacistBiasIcon;
