import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { SubmitButton } from 'lp-components'
import { CheckboxGroup, CheckboxWithText } from 'components'
import * as Types from 'types'
import {
  formatObjectsToIds,
  getOtherTypeId,
  isOtherType,
  parseIdsToObjects,
  persistSubmitSucceeded,
  replaceResources,
  serializeOptions,
  yankBy,
} from 'utils'

const propTypes = {
  ...formPropTypes,
  outcomeTypes: PropTypes.arrayOf(Types.outcomeType).isRequired,
}

function PartnerOutcomeTypeForm({
  handleSubmit,
  outcomeTypes,
  submitSucceeded,
  pristine,
  submitting,
}) {
  const [otherOutcomeType, definedOutcomeTypes] = useMemo(
    () => yankBy(outcomeTypes, isOtherType),
    [outcomeTypes]
  )

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="requestedOutcomeTypes"
          label={false}
          component={CheckboxGroup}
          format={formatObjectsToIds('outcomeTypeId')}
          parse={parseIdsToObjects('outcomeTypeId')}
          options={serializeOptions(definedOutcomeTypes)}
        />
        <Field
          name="otherText"
          label={otherOutcomeType.displayName}
          placeholder="Description..."
          component={CheckboxWithText}
        />
        <div className="button-wrapper">
          <SubmitButton
            {...{ pristine: submitSucceeded && pristine, submitting }}
          >
            Save Response
          </SubmitButton>
        </div>
      </div>
    </form>
  )
}

PartnerOutcomeTypeForm.propTypes = propTypes

function modifyBeforeSubmit({ otherTypeId, initialValues }) {
  return {
    beforeSubmit: ({ requestedOutcomeTypes, otherText, type }) => {
      const resources = replaceResources({
        old: initialValues.requestedOutcomeTypes,
        new: requestedOutcomeTypes,
      })
      if (otherText) resources.push({ outcomeTypeId: otherTypeId, otherText })
      return { outcomes: resources, type }
    },
  }
}

function modifyOtherTypeId({ outcomeTypes }) {
  return {
    otherTypeId: getOtherTypeId(outcomeTypes),
  }
}

function modifyInitialValues({ initialValues, otherTypeId }) {
  const [otherRequestedOutcomeType, requestedOutcomeTypes] = yankBy(
    initialValues.requestedOutcomeTypes,
    ({ outcomeTypeId }) => outcomeTypeId === otherTypeId
  )
  return {
    initialValues: {
      ...initialValues,
      requestedOutcomeTypes,
      otherText: otherRequestedOutcomeType
        ? otherRequestedOutcomeType.otherText
        : '',
      type: Types.REQUEST_FORM_TYPE,
    },
  }
}

export default compose(
  modifyProps(modifyOtherTypeId),
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyInitialValues),
  lpForm({
    name: 'partner-outcome-type',
    enableReinitialize: true,
    constraints: {
      otherText: {
        exclusion: {
          within: [' '],
          message: '^Description is required',
        },
      },
    },
  }),
  persistSubmitSucceeded()
)(PartnerOutcomeTypeForm)
