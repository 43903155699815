import * as React from "react";
const CriticalThinkingProblemSolvingIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <path
      d="M21.2295 34.8874V35.1147C21.4335 35.8234 20.825 36.3048 20.8952 37.067C20.8785 38.1267 21.5338 38.9624 22.353 39.4204C22.5202 45.4309 31.3177 45.5714 31.6618 39.5675C33.6914 38.9958 34.0826 36.6257 32.7987 35.1147C32.7418 33.6071 33.337 32.1328 34.4438 31.1165C39.0748 27.0615 39.2119 19.2758 34.6845 15.097C27.8567 8.34427 15.9898 13.3887 16.0001 22.9596C15.9867 26.0083 17.2707 28.9501 19.4909 31.0361C20.6144 32.0791 21.2295 33.4433 21.2295 34.8874ZM26.7533 14.6693C31.3174 14.4286 35.4638 18.4168 35.3534 23C35.3534 25.3267 34.3637 27.5731 32.6383 29.151C31.3141 30.3544 30.4448 32.0125 30.204 33.7775H28.5689V26.403L31.3375 23.6317C32.568 22.3915 30.6888 20.5094 29.445 21.7396L27.2917 23.8925L25.4092 21.7463C24.2523 20.4393 22.2494 22.1909 23.3996 23.5114L25.8907 26.3529V33.7775H23.8209C23.5668 32.0258 22.6841 30.3544 21.3198 29.084C19.6079 27.4794 18.6583 25.3132 18.6716 22.9732C18.685 18.5338 22.3095 14.803 26.75 14.6693H26.7533Z"
      fill="white"
    />
  </svg>
);
export default CriticalThinkingProblemSolvingIcon;
