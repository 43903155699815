import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as Types from 'types'
import { InfoModal } from 'components'
import {
  PartnerProgramTypeForm,
  RequestedDisciplinesForm,
  ProgramsResourcesInterestForm,
  PartnerOutcomeTypeForm,
} from '../forms'
import { ArtsProgrammingResourceForm } from '../../survey/forms'
import * as apiActions from 'api-actions'
import {
  communityEnumerable,
  getSpecificInterestTypeId,
  removeSpecificInterestType,
  replaceResources,
  displaySubmitFailure,
  useDynamicInfoModal,
  useCommunity,
} from 'utils'
import * as flashActions from 'redux-flash'
import { WHATS_HAPPENING_NOW_TEXT } from 'config'
import { get, isEmpty } from 'lodash'

const propTypes = {
  schoolId: PropTypes.number.isRequired,
  school: Types.school.isRequired,
  updateSchool: PropTypes.func.isRequired,
  resourceTypes: PropTypes.array.isRequired,
  programTypes: PropTypes.array.isRequired,
  outcomeTypes: PropTypes.array.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  detail: Types.detail.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function Summary({
  school,
  updateSchool,
  resourceTypes,
  programTypes,
  outcomeTypes,
  detail,
  disciplines,
  flashSuccessMessage,
}) {
  const community = useCommunity()
  const currentSchoolYearId = detail.schoolYearId
  const [showInfoModal, infoModalContent, setShowInfoModalWithContent] =
    useDynamicInfoModal()
  const specificInterestTypeId = useMemo(
    () => getSpecificInterestTypeId(resourceTypes),
    [resourceTypes]
  )
  const showInterestsQuestion = !!specificInterestTypeId
  const updateSchoolOnSubmit = useCallback(
    (params) => updateSchool(school.id, currentSchoolYearId, params),
    [school, currentSchoolYearId]
  )
  const onSuccess = useCallback(
    () => flashSuccessMessage(Types.SCHOOL_SUCCESS_MESSAGE),
    [flashSuccessMessage]
  )
  const outcomesModalContent = useMemo(() => {
    return communityEnumerable.getInfoModalContent(outcomeTypes)
  }, [outcomeTypes])

  return (
    <div>
      <p>{WHATS_HAPPENING_NOW_TEXT}</p>
      <section className="card">
        <h2>
          What arts-programming resource(s) would you be interested in receiving
          for your school? Check all that apply.
        </h2>
        <ArtsProgrammingResourceForm
          resourceTypes={removeSpecificInterestType(resourceTypes)}
          initialValues={{ requestedResources: school.requestedResources }}
          onSubmit={updateSchoolOnSubmit}
          onSubmitSuccess={onSuccess}
        />
      </section>
      <section className="card">
        <h2>
          {get(
            community,
            'displayText.schoolPortal.summary.partnerProgramTypes.header',
            'What types of partner programs would you be interested in having at your school? Check all that apply.'
          )}
        </h2>
        <PartnerProgramTypeForm
          programTypes={programTypes}
          initialValues={{
            requestedProgramTypes: school.requestedProgramTypes,
          }}
          onSubmit={updateSchoolOnSubmit}
          onSubmitSuccess={onSuccess}
          onSubmitFail={displaySubmitFailure}
        />
      </section>
      <section className="card">
        <h2>
          Indicate the{' '}
          <button
            className="link-secondary"
            type="button"
            onClick={() => setShowInfoModalWithContent(outcomesModalContent)}
          >
            qualities and desired outcomes
          </button>{' '}
          you seek in a partner. Check all that apply.
        </h2>
        <PartnerOutcomeTypeForm
          outcomeTypes={outcomeTypes}
          initialValues={{
            requestedOutcomeTypes: school.requestedOutcomeTypes,
          }}
          onSubmit={updateSchoolOnSubmit}
          onSubmitSuccess={onSuccess}
          onSubmitFail={displaySubmitFailure}
        />
      </section>
      <section className="card">
        <h2>
          An arts and cultural partnership can help enhance your curriculum and
          augment your arts program. What, if any, types of discipline area(s)
          would you be interested in pursuing with an external arts program
          provider (i.e., arts organization)?
        </h2>
        <RequestedDisciplinesForm
          disciplines={disciplines}
          initialValues={{ subDisciplines: school.requestedPrograms }}
          onSubmit={updateSchoolOnSubmit}
          onSubmitSuccess={onSuccess}
        />
      </section>
      {showInterestsQuestion && (
        <section className="card">
          <h2>School's Interests</h2>
          <ProgramsResourcesInterestForm
            initialValues={{
              otherText: get(school, 'requestedSpecificInterest.otherText', ''),
            }}
            beforeSubmit={({ otherText }) => {
              if (isEmpty(otherText)) return null
              return { resourceTypeId: specificInterestTypeId, otherText }
            }}
            onSubmit={(newInterest) => {
              const resources = replaceResources({
                old: [school.requestedSpecificInterest],
                new: [newInterest],
              })
              return updateSchoolOnSubmit({
                resources,
                type: Types.REQUEST_FORM_TYPE,
              })
            }}
            onSubmitSuccess={onSuccess}
          />
        </section>
      )}
      {showInfoModal && (
        <InfoModal
          onClose={() => setShowInfoModalWithContent(false)}
          content={infoModalContent.content}
          header={infoModalContent.header}
        />
      )}
    </div>
  )
}

Summary.propTypes = propTypes
Summary.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    schoolId: globalSchoolSelectors.currentSchoolId(state),
    detail: globalSchoolSelectors.currentDetail(state),
    school: globalSchoolSelectors.school(state),
    resourceTypes: globalSelectors.resourceTypes(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
    disciplines: globalSelectors.definedDisciplines(state),
    outcomeTypes: globalSelectors.outcomeTypes(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  updateSchool: apiActions.updateSchool,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Summary)
