import * as React from "react";
const SocialEmotionalIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <path
      d="M18.2494 17.002C15.7058 17.002 13.6247 19.2018 13.6247 22.0015C13.6247 24.8012 15.7058 27.001 18.2494 27.001C19.1744 27.001 20.0692 26.601 20.9965 26.0011C20.7653 25.4012 20.5618 24.8012 20.5618 24.0013C20.5618 22.2015 21.2254 20.7756 22.1527 19.3758C21.459 17.9759 19.8704 17 18.2494 17V17.002ZM36.7483 17.002C35.1296 17.002 33.541 17.9759 32.845 19.3778C33.77 20.7776 34.4359 22.2035 34.4359 24.0033C34.4359 24.8032 34.2324 25.4032 34.0012 26.0031C34.9261 26.603 35.821 27.003 36.7483 27.003C39.2919 27.003 41.373 24.8032 41.373 22.0035C41.373 19.2038 39.2919 17.004 36.7483 17.004V17.002ZM27.4988 19.0018C24.9437 19.0018 22.8741 21.2396 22.8741 24.0013C22.8741 26.763 24.9437 29.0008 27.4988 29.0008C30.054 29.0008 32.1236 26.763 32.1236 24.0013C32.1236 21.2396 30.054 19.0018 27.4988 19.0018ZM13.4073 27.001C11.095 27.201 9 28.8008 9 31.0006V35.0002H15.9371V33.0004C15.9371 31.4006 16.6608 30.0007 17.817 29.0008C16.1984 28.8008 14.5635 28.2009 13.4073 27.001ZM41.5903 27.001C40.4342 28.2009 38.8016 28.8008 37.183 29.0008C38.3392 30.0007 39.0629 31.4006 39.0629 33.0004V35.0002H46V31.0006C46 28.8008 43.905 27.201 41.5927 27.001H41.5903ZM22.6568 29.0008C20.3444 29.2008 18.2494 30.8006 18.2494 33.0004V37H36.7483V33.0004C36.7483 30.8006 34.6533 29.2008 32.3409 29.0008C30.9535 30.2007 29.3487 31.0006 27.4988 31.0006C25.649 31.0006 24.0442 30.2007 22.6568 29.0008Z"
      fill="white"
    />
  </svg>
);
export default SocialEmotionalIcon;
