import * as React from "react";
const TraumaInformedIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <rect
      x={15.0004}
      y={35.4886}
      width={7.79825}
      height={2.50899}
      rx={0.5}
      transform="rotate(45.27 15.0004 35.4886)"
      fill="white"
      stroke="white"
    />
    <path
      d="M29.4955 25.2007L35.617 18.8327C37.1204 17.2697 37.1204 14.7353 35.617 13.1723C34.1136 11.6092 31.678 11.6092 30.1757 13.1723L29.4956 13.88L28.8155 13.1723C27.3121 11.6092 24.8764 11.6092 23.3742 13.1723C21.8708 14.7353 21.8708 17.2696 23.3742 18.8327L29.4955 25.2007ZM41.4194 26.2272C40.6518 25.4257 39.4016 25.4257 38.6352 26.2272L34.2113 30.832C33.8422 31.2163 33.3426 31.432 32.8191 31.432H27.9206C27.5947 31.432 27.2892 31.2011 27.237 30.8649C27.17 30.436 27.4868 30.0669 27.8854 30.0669H31.8244C32.7317 30.0669 33.4663 29.3018 33.4663 28.3598C33.4663 27.4177 32.7305 26.6526 31.8244 26.6526H25.636C24.7674 26.6526 23.9294 27.0123 23.3163 27.6509L22.6361 28.3586L17.1916 34.0214C16.9361 34.2886 16.9361 34.7198 17.1916 34.9869L20.8568 38.7996C21.1123 39.0668 21.5279 39.0668 21.7845 38.7996L23.4264 37.0925C23.5491 36.9636 23.7171 36.8909 23.892 36.8909H32.441C33.2983 36.8909 34.1238 36.5429 34.7369 35.9161L41.4226 29.1191C42.1925 28.3177 42.1925 27.023 41.4226 26.2215L41.4194 26.2272Z"
      fill="white"
    />
  </svg>
);
export default TraumaInformedIcon;
