import { combineReducers } from 'redux'
import { selectorForSlice } from 'lp-redux-utils'
import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { orderBy, negate } from 'lodash'
import {
  orderAscendingToOtherByKey,
  removeParentDisciplines,
  isIgnoredType,
} from 'utils'
import { SCHOOL_YEAR_STATES } from 'types'

import { reducer as homeReducer, reducerKey as homeReducerKey } from './home'
import { reducer as mapReducer, reducerKey as mapReducerKey } from './map'
import {
  reducer as partnerPortalReducer,
  reducerKey as partnerPortalReducerKey,
} from './partner-portal'
import {
  reducer as schoolPortalReducer,
  reducerKey as schoolPortalReducerKey,
} from './school-portal'
import {
  reducer as educatorPortalReducer,
  reducerKey as educatorPortalReducerKey,
} from './educator-portal'

const reducerKey = 'root'

const globalReducerKey = 'global'
const slice = 'root.global'

const select = selectorForSlice(slice)
const initialState = {}

const globalReducer = handleActions(
  {
    [apiActions.fetchDisciplines]: setOnSuccess('allDisciplines'),
    [apiActions.fetchResourceTypes]: setOnSuccess('resourceTypes'),
    [apiActions.fetchSchoolYears]: setOnSuccess('schoolYears'),
    [apiActions.fetchProgramTypes]: setOnSuccess('programTypes'),
    [apiActions.fetchOutcomeTypes]: setOnSuccess('outcomeTypes'),
    [apiActions.fetchGrades]: setOnSuccess('grades'),
  },
  initialState
)

const selectors = {
  allDisciplines: select('allDisciplines'),
  resourceTypes: select('resourceTypes'),
  schoolYears: select('schoolYears'),
  programTypes: select('programTypes'),
  outcomeTypes: select('outcomeTypes'),
  grades: select('grades'),
}

selectors.disciplines = createSelector(
  [selectors.allDisciplines],
  function (allDisciplines) {
    if (!allDisciplines) return
    return removeParentDisciplines(allDisciplines)
  }
)

selectors.definedDisciplines = createSelector(
  [selectors.disciplines],
  function (disciplines) {
    if (!disciplines) return
    return disciplines.filter(negate(isIgnoredType))
  }
)

selectors.orderedProgramTypes = createSelector(
  [selectors.programTypes],
  function (programTypes) {
    return orderAscendingToOtherByKey(programTypes)
  }
)

// Order school years by most recent (DESC)
selectors.orderedSchoolYears = createSelector(
  [selectors.schoolYears],
  function (schoolYears) {
    if (!schoolYears) return
    return orderBy(schoolYears, 'position', 'desc')
  }
)

selectors.currentSchoolYear = createSelector(
  [selectors.schoolYears],
  function (schoolYears) {
    if (!schoolYears) return
    return schoolYears.find((schoolYear) => schoolYear.current)
  }
)

selectors.mostRecentPublishedSchoolYear = createSelector(
  [selectors.orderedSchoolYears],
  function (schoolYears) {
    if (!schoolYears) return
    return schoolYears.find(
      (schoolYear) => schoolYear.state === SCHOOL_YEAR_STATES.PUBLISHED
    )
  }
)

const reducer = combineReducers({
  [homeReducerKey]: homeReducer,
  [mapReducerKey]: mapReducer,
  [partnerPortalReducerKey]: partnerPortalReducer,
  [schoolPortalReducerKey]: schoolPortalReducer,
  [educatorPortalReducerKey]: educatorPortalReducer,
  [globalReducerKey]: globalReducer,
})

export { reducer, selectors, reducerKey }
