import * as React from "react";
const AcademicGoalsIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.7 37.9996V20.7123H43.6175V35.2521C43.6171 35.3729 43.5903 35.4924 43.5389 35.603C43.4874 35.7136 43.4124 35.8129 43.3185 35.8948C43.2246 35.9766 43.1139 36.0392 42.9932 36.0786C42.8726 36.118 42.7447 36.1334 42.6175 36.1237C38.2423 35.7897 34.374 36.4209 31.0745 38L45.7 37.9996ZM10 20.7123V37.9996H24.6295C21.33 36.4217 17.4617 35.7894 13.0865 36.1234C12.9593 36.1329 12.8314 36.1175 12.7108 36.0781C12.5902 36.0387 12.4795 35.9761 12.3856 35.8943C12.2917 35.8124 12.2167 35.7131 12.1652 35.6026C12.1138 35.492 12.0869 35.3726 12.0865 35.2517V20.7119L10 20.7123ZM13.9345 17.0338C19.0071 16.7884 23.3752 17.8802 26.9281 20.2814V37.2185C23.6088 35.2705 19.7175 34.288 15.3193 34.288C14.8619 34.288 14.4014 34.2986 13.9345 34.3207V17.0338ZM41.7674 17.0338V34.3192C36.7968 34.0938 32.4414 35.0669 28.7734 37.2193V20.2814C32.3272 17.8817 36.6917 16.7888 41.7674 17.0338Z"
      fill="white"
    />
  </svg>
);
export default AcademicGoalsIcon;
