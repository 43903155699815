import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Card,
  Content,
  ProfileMapDisplay,
  ExternalLink,
  PhoneLink,
  SearchResultContactLink,
  SchoolYearNav,
  OpenToPartnershipsIndicator,
} from 'components'
import { SchoolContent } from '../components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { connectQuery } from 'lp-hoc'
import { selectors as apiSelectors } from 'lp-redux-api'
import * as Types from 'types'
import { Element } from 'react-scroll'
import locationImg from 'images/map/dashboards/location.svg'
import phoneImg from 'images/map/dashboards/phone.svg'
import websiteImg from 'images/map/dashboards/web.svg'
import contactImg from 'images/map/dashboards/contact.svg'
import reportImg from 'images/map/dashboards/report.svg'
import cscImg from 'images/map/dashboards/csc.svg'
import * as routerActions from 'react-router-redux'
import {
  buildInternalApiUrl,
  toAddressString,
  removeSpecificInterestType,
  useCommunityText,
} from 'utils'
import { selectors as globalSelectors } from 'global-reducer'
import { first, get, size, some, take } from 'lodash'
import { Link } from 'react-router'
import { Spinner } from 'lp-components'

const propTypes = {
  clearSchool: PropTypes.func.isRequired,
  currentSchoolYear: Types.schoolYear.isRequired,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  programTypes: PropTypes.arrayOf(Types.programType).isRequired,
  resourceTypes: PropTypes.arrayOf(Types.resourceType).isRequired,
  outcomeTypes: PropTypes.arrayOf(Types.outcomeType).isRequired,
  fetchSchool: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  partnerLocations: PropTypes.arrayOf(Types.location),
  redirectToNotFound: PropTypes.func.isRequired,
  school: Types.school,
  schoolLocation: Types.location,
  year: PropTypes.number,
  schoolYears: PropTypes.arrayOf(Types.schoolYear),
  fetchActiveAndPublishedSchoolYears: PropTypes.func.isRequired,
}

const defaultPropTypes = {
  school: null,
  schoolLocation: null,
  schoolYears: null,
}

function School({
  clearSchool,
  currentSchoolYear,
  disciplines,
  programTypes,
  resourceTypes,
  outcomeTypes,
  fetchSchool,
  fetchActiveAndPublishedSchoolYears,
  isLoading,
  partnerLocations,
  redirectToNotFound,
  school,
  schoolLocation,
  schoolYears,
  year,
}) {
  const t = useCommunityText()
  const cscText =
    t('map.cscLinkText') || 'Creative Schools Certification Report'
  // Select school year by query param, current school year, OR latest year available (if no details are available for the current year)
  const selectedSchoolYearId = year || get(first(schoolYears), 'id')

  // Allow for attempting to access attributes on optional details
  const artsLiaison = get(school, 'artsLiaison') || {}
  const details = get(school, 'detail') || {}

  useEffect(() => {
    fetchActiveAndPublishedSchoolYears({ recent: false })
  }, [])

  useEffect(() => {
    if (!selectedSchoolYearId) return
    fetchSchool(selectedSchoolYearId).catch((e) => {
      if (e && e.status === 404) redirectToNotFound()
    })
  }, [selectedSchoolYearId])

  useEffect(() => {
    if (!schoolYears) return
    if (!some(schoolYears, ({ id }) => id === selectedSchoolYearId))
      redirectToNotFound()
  }, [schoolYears, selectedSchoolYearId])

  // Only clear selected school once (on unmount)
  useEffect(() => clearSchool, [])

  const getSchoolYear = useCallback(
    (id) => {
      if (isLoading) return
      return schoolYears.find((year) => year.id === id)
    },
    [schoolYears, isLoading]
  )

  const getSchoolYearDisplay = useCallback(
    (id) => {
      if (id === selectedSchoolYearId || isLoading) return
      const schoolYear = get(getSchoolYear(id), 'number')
      if (schoolYear) return `(${schoolYear})`
    },
    [selectedSchoolYearId, isLoading, getSchoolYear]
  )

  if (!schoolYears) return <Spinner />
  const numSchoolYears = size(schoolYears)

  return (
    <Content className="school-page">
      {/* Header */}
      <header className="profile-details-block grey-background">
        <div className="map-page-header interior-page" />
        <Element
          className="header-block content-block-container"
          name="details"
        >
          {!school && <div className="header-padding" />}
          {school && (
            <div>
              {numSchoolYears !== 0 && (
                <SchoolYearNav
                  baseUrl={`/schools/${school.id}`}
                  message="Showing school year data for:"
                  schoolYears={take(schoolYears, 5)}
                  selectedSchoolYearId={selectedSchoolYearId}
                />
              )}
              <Card>
                <div className="show-header">
                  <div className="text-block">
                    <h1 data-cy="school-header">{school.displayName}</h1>
                  </div>
                  <div className="options">
                    {school.openToPartnerships && (
                      <OpenToPartnershipsIndicator />
                    )}
                    {artsLiaison.email && (
                      <SearchResultContactLink
                        email={artsLiaison.email}
                        resultName={school.name}
                        contactName={artsLiaison.firstName}
                      >
                        <button className="button-alert">
                          Contact Arts Liaison
                        </button>
                      </SearchResultContactLink>
                    )}
                  </div>
                </div>

                <div className="header-details">
                  <div className="text-block">
                    {/* School details may not necessarily be available for _every_ year (enhancement pending) */}
                    {!school.detail && (
                      <p>
                        It looks like detailed information for this year has not
                        been provided or is incomplete for this school! If you
                        belong to this school and would like to update the
                        information displayed here, please{' '}
                        <Link to="/school-portal/sign-in">sign in</Link>.
                      </p>
                    )}
                    <ul className="details-block">
                      <li>
                        <img src={locationImg} alt="Location Pin" />
                        <p>{toAddressString(school.address)}</p>
                      </li>
                      <li>
                        <img src={phoneImg} alt="Arts Liaison's Phone Number" />
                        {artsLiaison.phone ? (
                          <p>
                            <PhoneLink phoneNumber={artsLiaison.phone} />
                          </p>
                        ) : (
                          <p className="empty-content">Phone Not Provided</p>
                        )}
                      </li>
                      <li>
                        <img src={websiteImg} alt="Website" />
                        {details.website ? (
                          <p>
                            <ExternalLink href={school.detail.website}>
                              School's Website
                            </ExternalLink>
                          </p>
                        ) : (
                          <p className="empty-content">Website Not Provided</p>
                        )}
                      </li>
                      <li>
                        <img src={contactImg} alt="Contact" />
                        {artsLiaison.fullName || artsLiaison.email ? (
                          <p>
                            Arts Liaison: {artsLiaison.fullName}
                            {artsLiaison.email && (
                              <SearchResultContactLink
                                email={artsLiaison.email}
                                resultName={school.name}
                                contactName={artsLiaison.firstName}
                              >
                                {artsLiaison.email}
                              </SearchResultContactLink>
                            )}
                          </p>
                        ) : (
                          <p className="empty-content">
                            Arts Liaison Not Provided
                          </p>
                        )}
                      </li>
                      {school.cpsSearchLink && (
                        <li>
                          <img src={reportImg} alt="School Profile" />
                          <p>
                            <ExternalLink href={school.cpsSearchLink}>
                              CPS School Profile
                            </ExternalLink>
                          </p>
                        </li>
                      )}
                      {(school.rubricReport ||
                        school.rawDataReport ||
                        (school.metricScore && school.metricScore.pdfUrl)) && (
                        <li>
                          <img src={cscImg} alt="School Type" />
                          <p>
                            {school.rubricReport && (
                              <ExternalLink href={school.rubricReport}>
                                Creative Schools Certification Rubric
                              </ExternalLink>
                            )}
                            {school.rawDataReport && (
                              <ExternalLink href={school.rawDataReport}>
                                {cscText}
                              </ExternalLink>
                            )}
                            {school.metricScore.pdfUrl && (
                              <ExternalLink href={school.metricScore.pdfUrl}>
                                {cscText}
                              </ExternalLink>
                            )}
                          </p>
                        </li>
                      )}
                      {school.report && (
                        <li>
                          <img src={reportImg} alt="School Report" />
                          <a
                            href={buildInternalApiUrl({
                              url: `/schools/${school.id}/report.pdf`,
                              query: { schoolYear: school.report.schoolYearId },
                            })}
                          >
                            Download School Report{' '}
                            {getSchoolYearDisplay(school.report.schoolYearId)}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="map-block">
                    <ProfileMapDisplay
                      location={schoolLocation}
                      center={{
                        lat: schoolLocation.latitude,
                        lng: schoolLocation.longitude,
                      }}
                      zoom={10}
                      hidePopup
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}
        </Element>
      </header>

      <SchoolContent
        school={school}
        isLoading={isLoading}
        schoolLocation={schoolLocation}
        partnerLocations={partnerLocations}
        currentSchoolYear={currentSchoolYear}
        disciplines={disciplines}
        programTypes={programTypes}
        outcomeTypes={outcomeTypes}
        resourceTypes={removeSpecificInterestType(resourceTypes)}
        selectedSchoolYearId={selectedSchoolYearId}
        getSchoolYearDisplay={getSchoolYearDisplay}
        getSchoolYear={getSchoolYear}
      />
    </Content>
  )
}

School.propTypes = propTypes
School.defaultPropTypes = defaultPropTypes

function mapStateToProps(state) {
  return {
    currentSchoolYear: globalSelectors.currentSchoolYear(state),
    disciplines: globalSelectors.definedDisciplines(state),
    programTypes: globalSelectors.orderedProgramTypes(state),
    resourceTypes: globalSelectors.resourceTypes(state),
    outcomeTypes: globalSelectors.outcomeTypes(state),
    school: selectors.school(state),
    isLoading: apiSelectors.isLoading(state, apiActions.fetchSchool),
    partnerLocations: selectors.partnerLocations(state),
    schoolLocation: selectors.currentSchoolLocation(state),
    schoolYears: selectors.orderedSchoolYears(state),
  }
}

function mapDispatchToProps(dispatch, { params: { id } }) {
  return {
    clearSchool: () => dispatch(actions.clearSchool()),
    fetchSchool: (schoolYearId) =>
      dispatch(apiActions.fetchSchool(id, schoolYearId)),
    redirectToNotFound: () => dispatch(routerActions.replace('/not-found')),
    fetchActiveAndPublishedSchoolYears: () =>
      dispatch(apiActions.fetchActiveAndPublishedSchoolYears({ schoolId: id })),
  }
}

export default compose(
  connectQuery('year'),
  connect(mapStateToProps, mapDispatchToProps)
)(School)
