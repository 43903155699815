import * as React from "react";
const SchoolCultureAndClimateIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M55 27.5C55 42.6878 42.6878 55 27.5 55C12.3122 55 0 42.6878 0 27.5C0 12.3122 12.3122 0 27.5 0C34.7935 0 41.7882 2.89731 46.9454 8.05456C52.1027 13.2118 55 20.2065 55 27.5Z"
      fill="#204A82"
    />
    <path
      d="M32.5334 15.0768V11.8344L34.3333 11.8237L34.3016 11.1198L27.2394 4.92297L20.1667 11.1198V11.8237L21.9349 11.8344V15.0662L27.2288 11.0558L32.5334 15.0768Z"
      fill="white"
    />
    <path
      d="M25.5668 28.7694C24.7739 28.7694 24.3333 29.6156 24.3333 29.6156H26.8333C26.8333 29.6156 26.3487 28.7694 25.5668 28.7694Z"
      fill="white"
    />
    <path
      d="M21 32.9999C21 32.9999 21.7604 34.6922 23.0833 34.6922C24.4063 34.6922 25.1667 33.0104 25.1667 33.0104C24.4958 33.2488 23.7939 33.387 23.0833 33.4203C22.3729 33.3787 21.6717 33.2372 21 32.9999L21 32.9999Z"
      fill="white"
    />
    <path
      d="M21.4111 28.7694C20.6151 28.7694 20.1667 29.6156 20.1667 29.6156H22.6667C22.6667 29.6156 22.2407 28.7694 21.4111 28.7694Z"
      fill="white"
    />
    <path
      d="M33.0777 34.6926C33.8849 34.6926 34.3333 33.8464 34.3333 33.8464H31.8333C31.8333 33.8464 32.2818 34.6926 33.0777 34.6926Z"
      fill="white"
    />
    <path
      d="M31.3859 38.077C30.0517 38.077 29.3333 39.7693 29.3333 39.7693C29.9934 39.526 30.6852 39.3843 31.3859 39.3489C32.1066 39.3846 32.8186 39.5262 33.5 39.7693C33.5 39.7693 32.72 38.077 31.3859 38.077Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.4381 43.2462V27.7662H43.63V26.6388L27.3538 14L11.0776 26.6275V27.8452H14.1273V43.2462H5C5.7814 44.4066 6.64357 45.5069 7.5797 46.5384H47.587C48.5265 45.478 49.3888 44.3475 50.1667 43.156L40.4381 43.2462ZM23.2656 37.3951C17.4613 37.3951 18.3467 25.4215 18.3467 25.4215H28.2829C28.2829 25.4215 29.0699 37.3951 23.2656 37.3951ZM26.4684 37.9249C27.0696 40.5631 28.3266 42.9533 30.9173 42.9533H30.961C36.7653 42.9533 35.9783 30.9234 35.9783 30.9234H29.6602C29.5201 31.9416 29.2933 32.9452 28.9825 33.9224H30.1521C30.1521 33.9224 29.7149 35.1739 28.9388 35.1739C28.7906 35.1706 28.6465 35.1237 28.5234 35.0386C28.1901 35.8645 27.7245 36.6268 27.1461 37.2935C26.9389 37.5244 26.7121 37.7357 26.4684 37.9249Z"
      fill="white"
    />
    <rect
      x={17.0776}
      y={23.5714}
      width={19.2529}
      height={20.2976}
      fill="white"
    />
  </svg>
);
export default SchoolCultureAndClimateIcon;
