import * as React from "react";
const CulturallyResponsiveIcon = (props) => (
  <svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#204A82" />
    <circle cx={27} cy={27} r={15.5} stroke="white" strokeWidth={3} />
    <path
      d="M33.5 27C33.5 31.512 32.5824 35.5228 31.1688 38.35C29.7103 41.2669 27.973 42.5 26.5 42.5C25.027 42.5 23.2897 41.2669 21.8312 38.35C20.4176 35.5228 19.5 31.512 19.5 27C19.5 22.488 20.4176 18.4772 21.8312 15.65C23.2897 12.7331 25.027 11.5 26.5 11.5C27.973 11.5 29.7103 12.7331 31.1688 15.65C32.5824 18.4772 33.5 22.488 33.5 27Z"
      stroke="white"
      strokeWidth={3}
    />
    <line x1={11} y1={28} x2={42} y2={28} stroke="white" strokeWidth={2} />
    <line x1={14} y1={21} x2={41} y2={21} stroke="white" strokeWidth={2} />
    <line x1={14} y1={35} x2={41} y2={35} stroke="white" strokeWidth={2} />
  </svg>
);
export default CulturallyResponsiveIcon;
